import React, { useEffect, useState } from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Container from '../components/container'
import SEO from "../components/seo"
import Hero from '../components/header/hero'
import Newsletter from '../components/footer/newsletter'

export default ({data}) => {

  useEffect(() => {
    const script = document.createElement('script')
    const inlineScript = document.createTextNode(`
      var LoadCDScripts = function () {
        var cdscript = document.createElement('script');
        cdscript.onerror = function (e) {
            document.head.removeChild(this);
            setTimeout(LoadCDScripts, 1750);
        };
        cdscript.onload = function () {
            CustomDonations.BuildForm({
            account: '0e361100-7650-4dee-afac-59eac256a396',
            form: '3089c957-24f4-4c7c-81e1-b52f3b8410aa',
            allocation: null, /* optional. Use an allocation ID, like 'AH664' (w/ quotes) to be default selected on form load */
            memberId: null, /* optional. If the current user is authenticated, you can insert their user ID, like '02943' (with quotes) */
            apiVersion: 'v1',
            interval: null, /* if null, then user must select one time or recurring (if enabled). Optionally can set to 'once', 'monthly','quarterly', or 'yearly' */
            loadingText: 'Loading Secure Form...',
            paymentVersion: 2, /*  1: for modal payment window. 2: for inline payment with Google/Apple Pay options */
            baseUrl: 'https://api.customdonations.com',
            mode: 'live', /* change to 'test' to run in test mode, 'live' for live. Use card 4242 4242 4242 4242 for testing. */
          });
        };
        cdscript.src = "https://api.customdonations.com/v1/js/form-builder.min.js?v=" + new Date().getTime();
        document.head.appendChild(cdscript);
      }
      LoadCDScripts();
    `)
    script.async = true
  
    script.appendChild(inlineScript)
    document.body.appendChild(script)
  
    return () => {
      document.body.removeChild(script)
    }
  }, []);

  return (
    <Layout>
      <SEO title={data.wordpressPage?.yoast_title !== undefined ? data.wordpressPage?.yoast_title : '' } meta={data.wordpressPage?.yoast_meta} path={data.wordpressPage?.slug} />
      <Hero mode="give" title={data.wordpressPage?.acf?.header_text} content={data.wordpressPage?.acf?.header_content} button={{title:'',url:'',target:''}} />
      <div className="contact" />
      <Container style={{margin: '4rem auto'}}>
        <div dangerouslySetInnerHTML={{__html: data.wordpressPage?.content}} style={{marginBottom: '2rem'}} />
        <div id="CustomDonationsForm"></div>
      </Container>
      <Newsletter />
    </Layout>
  )
}

export const pageQuery = graphql`
query giveQuery {
  wordpressPage(wordpress_id: {eq: 840}) {
    acf {
      header_content
      header_text
    }
    content
    title
    slug
    yoast_meta {
      name
      property
    }
    yoast_title
  }
}
`